import moment from 'moment';

const isNotNull = (value, errors, name, message) => {
  if (value == undefined || value == null || value === '') {
    errors[name] = message;
    return false;
  }
  return true;
};

const isNotNullDate = (value, errors, name, message) => {
  if (value == undefined || value == null || value === '' || (value.constructor === Object && Object.keys(value).length === 0)) {
    errors[name] = message;
    return false;
  }
  return true;
};

const notNullDate = value => {
  return value != undefined && value != null && value !== '' && value.constructor !== Object;
};

const notNull = value => {
  return value != undefined && value != null && value !== '';
};

const isNotNullSelect = (value, errors, name, message) => {
  if (!isNotNull(value, errors, name, message) || value.length == 0) {
    errors[name] = message;
  }
};

const compareDateNotNull = (value, valueSec, errors, name, nameSec, messageNull, messageValid, format, type) => {
  const valid = isNotNullDate(value, errors, name, messageNull);
  const validSec = isNotNullDate(valueSec, errors, nameSec, messageNull);
  if (valid && validSec) {
    compareDate(value, valueSec, errors, name, nameSec, messageValid, format, type);
  }
};

const compareDate = (value, valueSec, errors, name, nameSec, message, format, type) => {
  if (notNullDate(value) && notNullDate(valueSec)) {
    let valid = false;
    switch (type) {
      case 'equals': {
        const dateFrom = moment(value, format).startOf('date');
        const dateTo = moment(valueSec, format).startOf('date');
        valid = moment(dateFrom).isSame(dateTo);
        break;
      }
      case 'isSameOrBefore': {
        const dateFrom = moment(value, format).startOf('date');
        const dateTo = moment(valueSec, format).startOf('date');
        valid = moment(dateFrom).isSameOrBefore(dateTo);
        break;
      }
      case 'isBefore': {
        const dateFrom = moment(value, format).startOf('date');
        const dateTo = moment(valueSec, format).startOf('date');
        valid = moment(dateFrom).isBefore(dateTo);
        break;
      }

      case 'diffDay': {
        valid = moment(value, format).diff(moment(valueSec, format), 'day') >= 0;
        break;
      }
      default:
    }
    if (!valid) {
      errors[name] = message;
      errors[nameSec] = message;
    }
  }
};

const checkValuesNotInPast = (value, valueSec, valueThird, errors, name, namSec, nameThird, message) => {
  const date = moment();
  const year = date.year();
  const week = date.get('week');
  if (parseInt(value) < year) {
    errors[name] = message;
    return;
  }
  if (parseInt(value) == year && parseInt(valueSec) <= week) {
    errors[namSec] = message;
  }
  if (parseInt(value) == year && parseInt(valueThird) <= week) {
    errors[nameThird] = message;
  }
};
const passwordCheckValid = (value, errors, name, message) => {
  if ((value && value.length < 8) || !/\d/.test(value) || !/[A-Z]/.test(value) || !/[a-z]/.test(value)) {
    errors[name] = message;
  }
};

const areTheSame = (value, valueSec, errors, name, message) => {
  if (value !== valueSec) {
    errors[name] = message;
  }
};

const validEmailNotNull = (value, errors, name, messageNull, messageValid) => {
  if (isNotNull(value, errors, name, messageNull)) {
    validEmail(value, errors, name, messageValid);
  }
};

const validEmail = (value, errors, name, message) => {
  if (notNull(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    errors[name] = message;
  }
};

const validMobilePhoneNotNull = (value, errors, name, messageNull, messageValid) => {
  if (isNotNull(value, errors, name, messageNull)) {
    validMobilePhone(value, errors, name, messageValid);
  }
};
const validMobilePhone = (value, errors, name, message) => {
  if (notNull(value) && !/^\+[1-9]{1}[0-9]{8,14}$/i.test(value)) {
    errors[name] = message;
  }
};

const validUserName = (value, errors, name, message) => {
  if (notNull(value) && !/^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789._@+-]+$/i.test(value)) {
    errors[name] = message;
  }
};

const validFloatNotNull = (value, errors, name, messageNull, messageValid) => {
  if (isNotNull(value, errors, name, messageNull)) {
    return validFloat(value, errors, name, messageValid);
  }
  return false;
};

const validFloat = (value, errors, name, message) => {
  if (notNull(value) && isNaN(parseFloat(value))) {
    errors[name] = message;
    return false;
  }
  return true;
};

const validNumberNotNull = (value, errors, name, messageNull, messageValid) => {
  if (isNotNull(value, errors, name, messageNull)) {
    return validNumber(value, errors, name, messageValid);
  }
  return false;
};

const maxValidNumberNotNull = (value, maxLength, errors, name, messageNull, messageValid, messageMax) => {
  if (isNotNull(value, errors, name, messageNull)) {
    if (validNumber(value, errors, name, messageValid)) {
      return maxNumber(value, maxLength, errors, name, messageMax);
    }
  }
  return false;
};

const validNumber = (value, errors, name, message) => {
  if (notNull(value) && !/^\d+$/.test(value)) {
    errors[name] = message;
    return false;
  }
  return true;
};

const compareFloatProducts = (value, valueSec, errors, name, namSec, messageNull, messageValid, messageCompare) => {
  const valid = validFloatNotNull(value, errors, name, messageNull, messageValid);
  const validSec = validFloatNotNull(valueSec, errors, namSec, messageNull, messageValid);
  if (valid && validSec) {
    if (parseFloat(value) < parseFloat(valueSec)) {
      errors[namSec] = messageCompare;
      errors[name] = messageCompare;
    }
  }
};

const compareNumberBiNotNull = (value, valueSec, errors, name, namSec, messageNull, messageValid, messageCompare) => {
  if (isNotNull(value, errors, name, messageNull) && isNotNull(valueSec, errors, namSec, messageNull)) {
    if (validNumbeBi(value, errors, name, messageValid) && validNumbeBi(valueSec, errors, namSec, messageValid)) {
      if (parseInt(value) < parseInt(valueSec)) {
        errors[namSec] = messageCompare;
        errors[name] = messageCompare;
      }
    }
  }
};

const compareNumberNotNull = (value, valueSec, errors, name, namSec, messageNull, messageValid, messageCompare) => {
  if (isNotNull(value, errors, name, messageNull) && isNotNull(valueSec, errors, namSec, messageNull)) {
    if (validNumber(value, errors, name, messageValid) && validNumber(valueSec, errors, namSec, messageValid)) {
      if (parseInt(value) < parseInt(valueSec)) {
        errors[namSec] = messageCompare;
        errors[name] = messageCompare;
      }
    }
  }
};

const compareNumberDataStaticNotNull = (value, valueSec, errors, name, namSec, messageNull, messageValid, messageCompare) => {
  if (isNotNull(value, errors, name, messageNull) && isNotNull(valueSec, errors, namSec, messageNull)) {
    if (validNumber(value, errors, name, messageValid) && validNumber(valueSec, errors, namSec, messageValid)) {
      if (parseInt(value) - parseInt(valueSec) != 1) {
        errors[name] = messageCompare;
      }
    }
  }
};

const samllerThan = (value, valueSec, errors, name, messageValid) => {
  if (parseInt(value) >= parseInt(valueSec)) {
    errors[name] = messageValid;
  }
};

const compareNumberBi = (value, valueSec, errors, name, namSec, messageNull, messageValid, messageCompare) => {
  if (isNotNull(value, errors, name, messageNull) && isNotNull(valueSec, errors, namSec, messageNull)) {
    if (validNumbeBi(value, errors, name, messageValid) && validNumbeBi(valueSec, errors, namSec, messageValid)) {
      if (parseInt(value) < parseInt(valueSec)) {
        errors[namSec] = messageCompare;
        errors[name] = messageCompare;
      }
    }
  }
};

const validNumberBiNotNull = (value, errors, name, messageNull, messageValid) => {
  if (isNotNull(value, errors, name, messageNull)) {
    validNumbeBi(value, errors, name, messageValid);
  }
};

const validNumbeBi = (value, errors, name, message) => {
  if (notNull(value) && !/^-?\d+$/.test(value)) {
    errors[name] = message;
    return false;
  }
  return true;
};

const maxCharNotNull = (value, maxLength, errors, name, messageNull, messageValid) => {
  if (isNotNull(value, errors, name, messageNull)) {
    maxChar(value, maxLength, errors, name, messageValid);
  }
};

const maxNumber = (value, maxNumber, errors, name, message) => {
  if (notNull(value) && value > maxNumber) {
    errors[name] = message;
  }
};

const maxChar = (value, maxLength, errors, name, message) => {
  if (notNull(value) && value.length > maxLength) {
    errors[name] = message;
  }
};

export {
  maxValidNumberNotNull,
  maxNumber,
  checkValuesNotInPast,
  compareFloatProducts,
  compareNumberNotNull,
  compareNumberBiNotNull,
  validFloatNotNull,
  validFloat,
  compareDateNotNull,
  isNotNullDate,
  compareDate,
  isNotNull,
  passwordCheckValid,
  compareNumberDataStaticNotNull,
  areTheSame,
  validUserName,
  validEmailNotNull,
  validEmail,
  validNumber,
  samllerThan,
  validNumberNotNull,
  maxCharNotNull,
  maxChar,
  validNumberBiNotNull,
  validNumbeBi,
  compareNumberBi,
  isNotNullSelect,
  validMobilePhoneNotNull,
  validMobilePhone,
};
