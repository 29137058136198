import React from 'react';
import PropTypes from 'prop-types';

const renderField = props => {
  const totalSize = props.input.totalSize || 12;
  const labelSize = props.input.labelSize || 4;
  const componentSize = totalSize - labelSize;
  return (
    <div className={'row form-group ' + (props.meta.touched && props.meta.error ? 'has-error' : '')}>
      <label className={'col-md-' + labelSize + ' control-label'} htmlFor={props.input.name}>
        {props.title}
      </label>
      <div className={'col-md-' + componentSize}>
        <input
          {...props.input}
          autoComplete={props.autoComplete ? 'on' : 'off'}
          placeholder={props.placeholder}
          type={props.type}
          className="form-control "
        />
        <small className={props.meta.touched && props.meta.error ? 'help-block m-b-none error' : 'hidden'}>{props.meta.error}</small>
      </div>
    </div>
  );
};

renderField.propTypes = {
  autoComplete: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  labelSize: PropTypes.number,
};

export default renderField;
