/* eslint-disable import/default */
// Set up your application entry point here...
require('./assets/scss/style.scss');
require('./assets/css/animate.css');
const $ = require('jquery');
global.jQuery = require('jquery');
require('metismenu');
window.$ = $;
require('bootstrap');
require('bootbox');
import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'moment/locale/fr';
import 'moment/locale/de';
import React from 'react';
import { render } from 'react-dom';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import ErrorBoundary from './ErrorBoundary';
// Create an enhanced history that syncs navigation events with the store
const store = configureStore(history);
import HomeBase from './containers/homeBase';

//import App from './containers/app';
//import language from './containers/highOrders/language';
render(
  <ErrorBoundary>
    <Provider store={store}>
      <HomeBase />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('app'),
);
