// Set up your root reducer here...
import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import interests from './interests';

export default history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    interests,
  });
