import React from 'react';
import { Field, reduxForm } from 'redux-form';
import materialRenderField from '../common/renderField';
import renderMultiSelectBox from '../common/renderMultiSelectBox';

import renderTextareaField from '../common/renderTextareaField';
import renderCaptchaField from '../common/renderCaptchaField';
import { isNotNull, isNotNullSelect, validEmailNotNull, validMobilePhoneNotNull } from '../../../utils/managerValidationForm';
import PropTypes from 'prop-types';

const validate = (values, props) => {
  const errors = {};
  isNotNull(values.name, errors, 'name', 'Champs requis');
  isNotNull(values.surname, errors, 'surname', 'Champs requis');
  isNotNull(values.company, errors, 'company', 'Champs requis');
  //  isNotNull(values.address, errors, 'address', 'Champs requis');
  // isNotNull(values['postal-code'], errors, 'postal-code', 'Champs requis');
  //  isNotNull(values['address-level2'], errors, 'address-level2', 'Champs requis');
  validEmailNotNull(values.email, errors, 'email', 'Champs requis', 'Format invalide');
  //  validMobilePhoneNotNull(values.tel, errors, 'tel', 'Champs requis', 'Format invalide');
  isNotNullSelect(values.interests, errors, 'interests', 'Champs requis');
  //isNotNull(values.comment, errors, 'comment', 'Champs requis');
  isNotNull(values.token, errors, 'token', 'Champs requis');
  return errors;
};

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyCaptcha: 1,
    };
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.submitSucceeded && this.props.submitSucceeded) {
      this.props.reset();
      this.setState({ keyCaptcha: this.state.keyCaptcha + 1 });
    } else if (!prevProps.submitting && this.props.submitting) {
      this.setState({ keyCaptcha: this.state.keyCaptcha + 1 });
    }
  }

  render() {
    const { handleSubmit, interests, loadingForm, pristine, error, invalid, submitting } = this.props;
    let elements = [];
    interests.forEach((e, pos) => {
      elements.push({ name: e.name, id: pos });
    });

    const errorMessage = "Une erreur inconnue s'est produite.";
    return (
      <div>
        <form className="form-login" onSubmit={handleSubmit}>
          <div className={error != undefined ? 'danger-login' : 'hidden'}>
            <div>
              <i
                className="fa fa-times-circle"
                aria-hidden="true"
                style={{
                  marginLeft: '5px',
                  marginRight: '5px',
                }}
              />
              {errorMessage}
            </div>
          </div>

          <Field component={materialRenderField} type="text" className="form-control" title="Nom*" name="name" />
          <Field component={materialRenderField} type="text" className="form-control" title="Prénom*" name="surname" />
          <Field component={materialRenderField} type="text" className="form-control" title="Entreprise*" name="company" />
          <Field component={materialRenderField} type="text" className="form-control" title="Adresse" name="address" />
          <Field component={materialRenderField} type="text" className="form-control" title="NPA" name="postal-code" />
          <Field component={materialRenderField} type="text" className="form-control" title="Ville" name="address-level2" />
          <Field component={materialRenderField} type="text" className="form-control" title="Email*" name="email" />
          <Field component={materialRenderField} type="text" className="form-control" title="Téléphone" name="tel" />
          <div className="part-split" />
          <Field
            component={renderMultiSelectBox}
            type="text"
            className="form-control"
            elements={elements}
            title="Vos centres d'intérêts*"
            name="interests"
          />
          <div className="part-split" />
          <Field component={renderTextareaField} type="text" className="form-control" title="Commentaires" name="comment" />
          <Field keyCaptcha={this.state.keyCaptcha} component={renderCaptchaField} type="text" className="form-control" name="token" />
          <div className="clearfix">
            <div className="col-md-12 btn-send">
              {!loadingForm && !submitting && (
                <button
                  data-style="expand-right"
                  onClick={this.handleClickSubmit}
                  type="submit"
                  className="btn btn-success block full-width m-b"
                  disabled={pristine || submitting || invalid}
                >
                  Envoyer
                </button>
              )}
              {((loadingForm && (error == undefined || error == '')) || submitting) && (
                <i className="fa fa-circle-notch fa-spin fa-send-mail fa-fw" />
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

Contact.propTypes = {
  loadingForm: PropTypes.bool,
  fields: PropTypes.array,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  error: PropTypes.string,
  __: PropTypes.object,
  interests: PropTypes.array,
  submitSucceeded: PropTypes.bool,
  reset: PropTypes.func,
};

const ContactForm = reduxForm({ validate })(Contact);

export default ContactForm;
