import React from 'react';
import PropTypes from 'prop-types';
import Contact from '../../components/forms/contact';
import Footer from './footer';
import { SubmissionError } from 'redux-form';

import { sendMailToInfoteam, getInterests } from '../../actions';
import { connect } from 'react-redux';
import * as constants from '../../actions/constants';
import Swal from 'sweetalert2';

class ContactContent extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickSubmit = this.handleClickSubmit.bind(this);
    this.state = {
      loadingForm: false,
    };
  }

  componentDidMount() {
    this.props.getInterests();
  }

  handleClickSubmit(values) {
    const { sendMailToInfoteam } = this.props;
    let promise1 = new Promise(resolve =>
      this.setState({ loadingForm: true }, () => {
        return sendMailToInfoteam(values).then(result => {
          this.setState({ loadingForm: false });
          if (result.type == constants.MAIL_SEND + constants.SUCCESS) {
            resolve(result.payload);
          }
          resolve({ success: false });
        });
      }),
    );

    return promise1.then(function(result) {
      if (!result.success) {
        Swal.fire({
          type: 'error',
          title: 'Erreur',
          text: result.message || "Une erreur inconnue s'est produite. Essayer de recommencer.",
        });
        throw new SubmissionError({
          _error: 'Error Unknow!',
        });
      } else {
        Swal.fire({
          type: 'success',
          title: 'Mail envoyé !',
          text: 'Merci pour votre demande. Nous prendrons contact avec vous dans les plus brefs délais.',
        });
      }
    });
  }

  render() {
    const { interests } = this.props;
    return (
      <div className="content-form">
        <div className="content-form-inside">
          <div className="text-form">
            <span>Remplissez le formulaire pour que nos experts vous contactent gratuitement et sans engagement.</span>
            {!interests.loaded && <i className="fa fa-circle-notch fa-spin fa-send-mail fa-fw" />}
            {interests.loaded && (
              <Contact
                onSubmit={this.handleClickSubmit}
                loadingForm={this.state.loadingForm}
                form="contact"
                interests={interests.data}
                initialValues={{
                  tel: '+41',
                }}
              />
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

ContactContent.propTypes = {
  __: PropTypes.object,
  sendMailToInfoteam: PropTypes.func,
  getInterests: PropTypes.func,
  interests: PropTypes.object,
};

export default connect(
  state => {
    const { interests } = state;
    return { interests };
  },
  { sendMailToInfoteam, getInterests },
)(ContactContent);
