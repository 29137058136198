import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';

class renderCaptchaField extends Component {
  constructor(props) {
    super(props);
    this.recaptcha = React.createRef();
  }

  componentDidMount() {
    loadReCaptcha('6LdK5q4UAAAAAD28FZytveXS6j4-J_-GrSI8AOWy');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.keyCaptcha != undefined && prevProps.keyCaptcha != this.props.keyCaptcha) {
      this.recaptcha.execute();
    }
  }

  render() {
    const { meta, input } = this.props;
    return (
      <div className={'row form-group ' + (meta.touched && meta.error ? 'has-error' : '')}>
        <div className="col-md-12" htmlFor={input.name}>
          <ReCaptcha
            ref={ref => (this.recaptcha = ref)}
            sitekey="6LdK5q4UAAAAAD28FZytveXS6j4-J_-GrSI8AOWy"
            action="formBase"
            verifyCallback={token => {
              if (input.onChange != undefined) {
                input.onChange(token);
              }
            }}
          />
        </div>
      </div>
    );
  }
}

renderCaptchaField.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  keyCaptcha: PropTypes.number,
};

export default renderCaptchaField;
