import { RSAA } from 'redux-api-middleware';
import * as constants from './constants';

const API_URL = window.API_URL;

const buildBaseRequest = (method, url, requestName, getState, body) => {
  const newVar = {
    [RSAA]: {
      types: [requestName + constants.REQUEST, requestName + constants.SUCCESS, requestName + constants.FAILURE],
      endpoint: API_URL + url,
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };

  if (body) {
    newVar[RSAA].body = JSON.stringify(body);
  }
  return newVar;
};

export const apiPost = (url, requestName, getState, body, id) => {
  if (id) {
    url = url + id;
  }
  return buildBaseRequest('POST', url, requestName, getState, body);
};

export const apiPatch = (url, requestName, getState, body, id) => {
  if (id) {
    url = url + id;
  }
  return buildBaseRequest('PATCH', url, requestName, getState, body);
};

export const apiPut = (url, requestName, getState, body, id) => {
  if (id) {
    url = url + id;
  }
  return buildBaseRequest('PUT', url, requestName, getState, body);
};

export const apiFetch = (url, requestName, getState, id) => {
  if (id) {
    url = url + id;
  }
  return buildBaseRequest('GET', url, requestName, getState);
};

export const apiDelete = (url, requestName, getState, id) => {
  if (id) {
    url = url + id;
  }
  return buildBaseRequest('DELETE', url, requestName, getState);
};
