import * as constants from './constants';
import { apiPost, apiFetch } from './utils';

export const sendMailToInfoteam = function(values) {
  return (dispatch, getState) => {
    return dispatch(apiPost('/mails', constants.MAIL_SEND, getState, values));
  };
};

export const getInterests = function() {
  return (dispatch, getState) => {
    return dispatch(apiFetch('/interests', constants.GET_INTEREST, getState));
  };
};
