import React from 'react';
import PropTypes from 'prop-types';

export const renderMultiSelectBox = props => {
  const totalSize = props.input.totalSize || 12;
  const labelSize = props.input.labelSize || 4;
  const componentSize = totalSize - labelSize;
  const allCheckBox = props.elements.map((option, pos) => (
    <div key={'input-check' + pos} className="checkbox-multi">
      <label className="container-checkbox">
        {option.name}
        <input
          type="checkbox"
          name={props.input.name + pos}
          checked={Array.isArray(props.input.value) && props.input.value.indexOf(option.id) !== -1}
          onBlur={e => {
            if (props.input.onBlur != undefined) {
              props.input.onBlur();
            }
          }}
          onChange={e => {
            const newValue = [...props.input.value];
            if (e.target.checked) {
              newValue.push(option.id);
            } else {
              newValue.splice(newValue.indexOf(option.id), 1);
            }
            if (props.input.onChange != undefined) {
              props.input.onChange(newValue);
            }
          }}
        />
        <span className="checkmark" />
      </label>
    </div>
  ));
  return (
    <div className={'row form-group ' + (props.meta.touched && props.meta.error ? 'has-error' : '')}>
      <label className={'col-md-' + labelSize + ' control-label'} htmlFor={props.input.name}>
        {props.title}
      </label>
      <div className={'col-md-' + componentSize}>
        <label className="switch m-t-sm">{allCheckBox}</label>
        <small className={props.meta.touched && props.meta.error ? 'help-block m-b-none error' : 'hidden'}>{props.meta.error}</small>
      </div>
    </div>
  );
};

renderMultiSelectBox.propTypes = {
  title: PropTypes.string,
  elements: PropTypes.array,
  meta: PropTypes.object,
  input: PropTypes.object,
};

export default renderMultiSelectBox;
