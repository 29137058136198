import React from 'react';
import PropTypes from 'prop-types';

const renderTitleField = props => {
  const totalSize = props.totalSize != undefined ? props.totalSize : 12;
  const labelSize = props.labelSize != undefined ? props.labelSize : 4;
  const componentSize = totalSize - labelSize;
  return (
    <div className={'row form-group ' + (props.meta.touched && props.meta.error ? 'has-error' : '')}>
      <label className={'col-md-' + labelSize + ' control-label'} htmlFor={props.input.name}>
        {props.title}
      </label>
      <div className={'col-md-' + componentSize}>
        <textarea {...props.input} placeholder={props.placeholder} className="form-control " style={{ height: 200 }} />
        <small className={props.meta.touched && props.meta.error ? 'help-block m-b-none error' : 'hidden'}>{props.meta.error}</small>
      </div>
    </div>
  );
};

renderTitleField.propTypes = {
  totalSize: PropTypes.number,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  labelSize: PropTypes.number,
};

export default renderTitleField;
