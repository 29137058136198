import React from 'react';
import PropTypes from 'prop-types';

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content-footer">
        <div className="row">
          <div className="col-md-6">
            <a href="https://www.infoteam.ch/" rel="noopener noreferrer" target="_blank">
              <img alt="Infoteam logo" className="img-logo-footer" src={require('../../assets/img/logo_infoteam.png')} />
            </a>
          </div>
          <div className="col-md-6">
            <a href="https://www.erpxperts.ch/" rel="noopener noreferrer" target="_blank">
              <img alt="ErpExperts logo" className="img-logo-footer" src={require('../../assets/img/Logo_ERPXperts.png')} />
            </a>
          </div>
        </div>
        <span className="text-footer">Tous droits réservés © - Infoteam Informatique Holding SA</span>
      </div>
    );
  }
}

Footer.propTypes = {
  __: PropTypes.object,
};
