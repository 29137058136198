import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from './parts/header';
import Contact from './parts/contact';

class HomeBase extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content animated fadeIn">
        <div className="logo-header">
          <img alt="PME365" className="img-logo-header" src={require('../assets/img/PME_365_logo_neg.png')} />
        </div>
        <Header />
        <Contact />
      </div>
    );
  }
}

HomeBase.propTypes = {};

export default connect(
  () => {
    return {};
  },
  {},
)(HomeBase);
