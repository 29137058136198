import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';

const createStoreWithMiddleware = applyMiddleware(thunk, apiMiddleware)(createStore);

export default function configureStore(history, initialState) {
  return createStoreWithMiddleware(rootReducer(history), initialState);
}
