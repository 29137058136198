import React from 'react';
import PropTypes from 'prop-types';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="text-header">
        <h3 className="header-title">Nous accélerons votre transition digitale</h3>
        <span>- Analyse de votre stratégie digitale</span>
        <br />
        <span>- Optimisation de vos processus de travail</span>
        <br />
        <span>- Développement et intégration des outils digitaux</span>
        <br />
      </div>
    );
  }
}

Header.propTypes = {
  __: PropTypes.object,
};
